import { graphql } from "gatsby"
import React from "react"
import ItemDetails from "../components/ItemDetails"
import Layout from "../components/layout"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!, $id: ID) {
    detailsItem: gcms {
      libraryHighlight(locales: $locale, where: { id: $id }) {
        title
        longDescription {
          html
        }
        sliderImages(locales: en) {
          url
        }
      }
    }
  }
`
const LibraryHighlightsDetails = ({ t, data }) => {
  return (
    <Layout>
      <ItemDetails t={t} detailItem={data.detailsItem.libraryHighlight} detailsType= "highlights" />
    </Layout>
  )
}

export default LibraryHighlightsDetails
